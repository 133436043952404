.global__notification-bar {
    height: $notifyBarHeight;
    padding: 0 calc(5rem/16);
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: $pinkToGoldGradient;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    &.is-dark-mode {
        //background: $darkestGrey;
        p {
            //color: $white;
        }
    }

    p {
        margin: 0 calc(10rem/16) 0 0;
        font-size: calc(10rem/16);
        font-family: $secondaryFont;
        font-weight: 500;
        line-height: 1;
        color: $black;
        text-transform: uppercase;
    }

    .ickonic-button {
        padding: calc(2rem/16) calc(10rem/16) !important;
        width: auto !important;
        height: auto !important;
        margin: 0;
        font-size: calc(12rem/16) !important;
        white-space: nowrap;
        line-height: 1 !important;
        background: $white !important;
        color: $black !important;
        &:hover,
        &:focus,
        &:active {
            background: $lighterGrey !important;
        }
    }

    @media screen and (min-width: $breakpointLargeMobile) {
        p {
            font-size: calc(12rem/16);
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        p {
            font-size: calc(14rem/16);
        }
    }
}
