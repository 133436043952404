@keyframes boxShadowGlowPulseGold {
    0% {
        box-shadow: 0 0 0 0 rgba(255,201,63,0.5);
    }

    100% {
        box-shadow: 0 0 0 calc(20rem/16) rgba(255,201,63,0);
    }
}

@keyframes boxShadowGlowPulseBlue {
    0% {
        box-shadow: 0 0 0 0 rgba(70,118,230,0.7);
    }

    100% {
        box-shadow: 0 0 0 calc(20rem/16) rgba(70,118,230,0);
    }
}

@keyframes pulseSoftAccent {
    0% {
        box-shadow: 0 0 calc(7rem/16) 0 #ffdb58;
    }

    50% {
        box-shadow: 0 0 calc(16rem/16) 0 #ffdb58;
    }

    100% {
        box-shadow: 0 0 calc(7rem/16) 0 #ffdb58;
    }
}

@keyframes pulseSoftAccentInner {
    0% {
        box-shadow: inset 0 0 calc(15rem/16) 0 #ffdb58;
    }

    50% {
        box-shadow: inset 0 0 calc(35rem/16) 0 #ffdb58;
    }

    100% {
        box-shadow: inset 0 0 calc(15rem/16) 0 #ffdb58;
    }
}

@keyframes boxShadowGlowPulsePink {
    0% {
        box-shadow: 0 0 0 0 rgba(223,75,220,0.5);
    }

    100% {
        box-shadow: 0 0 0 calc(20rem/16) rgba(223,75,220,0);
    }
}


@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(232,62,7,0.5);
    }

    100% {
        box-shadow: 0 0 0 calc(10rem/16) rgba(232,62,7,0);
    }
}

@keyframes pulseSoft {
    0% {
        box-shadow: 0 0 calc(10rem/16) 0 rgba(187,54,184,0.2);
    }

    50% {
        box-shadow: 0 0 calc(30rem/16) 0 rgba(187,54,184,0.4);
    }

    100% {
        box-shadow: 0 0 calc(10rem/16) 0 rgba(187,54,184,0.2);
    }
}

@keyframes pulseHuge {
    0% {
        box-shadow: 0 0 0 0 rgba(223,75,220,1);
    }

    100% {
        box-shadow: 0 0 0 calc(30rem/16) rgba(223,75,220,0);
    }
}

@keyframes boxShadowGlowPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255,201,63,0.5);
    }

    100% {
        box-shadow: 0 0 0 calc(20rem/16) rgba(255,201,63,0);
    }
}

@keyframes translateRight {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(calc(10rem/16));
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes translateRight {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(calc(10rem/16));
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes translateLeft {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(calc(-10rem/16));
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@keyframes sideToSideLoading {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes revealCircleMask {
    0% {
        clip-path: circle(100% at 50% 50%);
    }
    100% {
        clip-path: circle(0% at 50% 50%);
    }
}

@keyframes widen {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
