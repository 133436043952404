.front-page__finale {
    padding: 3rem 0;
    .front-page__gradient-bottom {
        top: unset;
        bottom: 0;
    }

    .front-page__faqs {
        padding: 1rem 2rem 5rem 2rem;
        max-width: calc(900rem/16);
        margin: 0 auto;
        h1 {
            font-family: $tertiaryFont;
            text-transform: uppercase;
            font-weight: bold;
            text-align: center;
            span {
                display: block;
                background: linear-gradient(180deg, #4676E6 0%, #b760b4 100%);
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
            }

            @media screen and (min-width: $breakpointLargeMobile) {
                font-size: calc(35rem/16);
            }

            @media screen and (min-width: $breakpointDesktop) {
                font-size: calc(40rem/16);
            }

            @media screen and (min-width: $breakpointWide) {
                font-size: calc(45rem/16);
            }
        }

        @media screen and (min-width: $breakpointTablet) {
            padding: 1rem 2rem 7rem 2rem;
        }

        @media screen and (min-width: $breakpointDesktop) {
            padding: 1rem 2rem 10rem 2rem;
        }
    }
}
