*,
*:before,
*:after {
    box-sizing: border-box;
    word-wrap: break-word;
}

// 1. Set default font family.
// 2. Prevent iOS text size adjust after orientation change, without disabling user zoom.
html {
    font-family: $baseFont;         // 1
    -ms-text-size-adjust: 100%;     // 2
    -webkit-text-size-adjust: 100%; // 2
}

// Uses dark mode values for color / bg
// so that users requesting for the first
// time do not see a white screen.
body {
    margin: 0;
    background: $bodyBGColor;
    color: $baseFontColor;
    font-family: $baseFont;
    font-size: $baseFontSize;
    font-weight: $baseFontWeight;
    line-height: $baseLineHeight;

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: $midGrey $darkerGrey;
    .has-alt-font {
        font-family: $secondaryFont;
    }

}

/* Webkit */
::-webkit-scrollbar {
    width: calc(8rem/16);
}

::-webkit-scrollbar-track {
    background: $darkerGrey;
}

::-webkit-scrollbar-thumb {
    background: lighten($darkGrey, 10%);
    border-radius: calc(4rem/16);
}

::-webkit-scrollbar-thumb:hover {
    background: $midGrey;
}

main {
    background: $darkModeMid;
    min-height: calc(100vh - $headerHeight);
    transition: background $darkModeTransitionTimer $sexyEase;
    &.has-notification-bar {
        min-height: calc(100vh - $headerHeight - $notifyBarHeight);
        .container {
            margin-top: $headerHeight + $notifyBarHeight;
        }
    }

    .container {
        width: 100%;
        margin-top: $headerHeight;
        &.has-no-header {
            margin-top: 0;
        }

        &.menu-is-desktop {
            .container__inner {
                padding: 2rem;
            }
        }

        .container__inner {
            padding: 1rem;
        }
    }

    &.has-light-mode {
        background: $lightestGrey;
        color: $black;
    }

    &.is-dark-mode {
        background: $bodyBGColor;
    }
}

// Viewport declarations --> https://developer.mozilla.org/en-US/docs/Web/CSS/@viewport
@-webkit-viewport {
    width: device-width;
    zoom: 1;
}

@-moz-viewport {
    width: device-width;
    zoom: 1;
}

@-ms-viewport {
    width: device-width;
    zoom: 1;
}

@-o-viewport {
    width: device-width;
    zoom: 1;
}

@viewport {
    width: device-width;
    zoom: 1;
}

// HTML5 display definitions
// ==========================================================================

// Correct `block` display not defined for any HTML5 element in IE 8/9.
// Correct `block` display not defined for `details` or `summary` in IE 10/11
// and Firefox.
// Correct `block` display not defined for `main` in IE 11.
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}

// 1. Correct `inline-block` display not defined in IE 8/9.
// 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
audio,
canvas,
progress,
video {
    display: inline-block;    // 1
    vertical-align: baseline; // 2
}

// Prevent modern browsers from displaying `audio` without controls.
// Remove excess height in iOS 5 devices.
audio:not([controls]) {
    display: none;
    height: 0;
}

// Address `[hidden]` styling not present in IE 8/9/10.
// Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.
[hidden],
template {
    display: none;
}


// Text-level semantics
// ==========================================================================

// Address styling not present in IE 8/9/10/11, Safari, and Chrome.
abbr[title] {
    border-bottom: 1px dotted;
}

// Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
b,
strong {
    font-weight: bold;
}

// Address styling not present in Safari and Chrome.
em,
i,
dfn {
    font-style: italic;
}

// Address styling not present in IE 8/9.
mark {
    background: #ff0;
    color: #000;
}

// Address inconsistent and variable font size in all browsers.
small {
    font-size: 80%;
}

// Prevent `sub` and `sup` affecting `line-height` in all browsers.
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

// Embedded content
// ==========================================================================

// Remove border when inside `a` element in IE 8/9/10.
img {
    max-width: 100%;
    height: auto;
    border: 0;
    vertical-align: bottom;
}

// Correct overflow not hidden in IE 9/10/11.
svg:not(:root) {
    overflow: hidden;
}

// Tables
// ==========================================================================

// Remove most spacing between table cells.
table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: $tableFontSize;
}

tr {
    &:nth-child(even) {
        th,
        td {
            background: $tableStripe;
        }
    }
}

td,
th {
    padding: $tableCellPadding;
}

blockquote {
    box-shadow: rgb(168, 72, 150) 0px 0px 0px 0.0625rem inset;
    color: rgb(168, 72, 150);
    font-size: 1.25rem;
    font-style: italic;
    line-height: 1.5;
    position: relative;
    background: rgb(27, 27, 27);
    margin: 2rem 0px;
    overflow: hidden;
    padding: 2rem 6.25rem 2rem 2rem;
}
