.popup--basic {
    &.is-transitioning-in {
        .popup__content {
            opacity: 1;
            transform: scale(1);
            transition: opacity .45s $sexyEase .01s, transform .45s $sexyEase .01s;
        }

        .popup__close {
            opacity: 1;
            transition: opacity .45s $sexyEase .01s, background .25s $sexyEase;
        }
    }
}

.popup__close {
    width: calc(40rem/16);
    height: calc(40rem/16);
    position: absolute;
    top: calc(10rem/16);
    right: calc(10rem/16);
    cursor: pointer;
    padding: calc(5rem/16);
    border-radius: calc(20rem/16);
    opacity: 0;
    transition: opacity .45s $sexyEase, background .25s $sexyEase;
    &:hover,
    &:focus,
    &:active {
        background: $white;
        box-shadow: 0 0 calc(2rem/16) calc(1rem/16) rgba(0,0,0,0.2);
        svg {
            * {
                fill: $black;
            }
        }
    }

    svg {
        * {
            fill: $white;
            transition: fill .25s $sexyEase;
        }
    }
}

.popup__content {
    max-width: 80%;
    max-height: 80%;
    position: relative;
    opacity: 0;
    transform: scale(.95);
    overflow-y: auto;
    transition: opacity .25s $sexyEase, transform .25s $sexyEase;
    @media screen and (min-width: $breakpointSmallTablet) and (min-height: calc(1000em/16)) {
        margin-bottom: 20vh;
    }
}
