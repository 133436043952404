button,
input,
optgroup,
select,
textarea {
    color: inherit; // 1
    font: inherit;  // 2
}

label {
    color: $labelColor;
    font-size: $labelFontSize;
    text-align: left;
}

input[type="text"],
input[type="date"],
input[type="time"],
input[type="tel"],
input[type="datetime"],
input[type="datetime-local"],
input[type="url"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="file"],
input[type="password"],
select,
.textbox,
textarea {
    background: none;
    width: 100%;
    border: none;
    margin: 0 0 1rem 0;
    padding: calc(26rem/16) calc(12rem/16) calc(8rem/16) calc(12rem/16);
    border-radius: calc(3rem/16);
    color: $black;
    font-size: calc(14rem/16);
    box-shadow: 0 0 calc(2rem/16) calc(1rem/16) rgba(0,0,0,0.12);
    &:last-of-type {
        margin: 0;
    }

    &:active,
    &:focus {
        outline: none;
    }

    &:hover {
        box-shadow: none;
    }
}

button,
input[type=checkbox] {
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.form__input-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .input-wrapper__cover {
        background: $white;
        width: 100%;
        border-radius: calc(3rem/16);
        max-height: calc(58rem/16);
        input {
            position: relative;
            z-index: 1;
        }
    }

    label {
        font-family: $baseFont;
        font-weight: 400;
        position: absolute;
        top: calc(15rem/16);
        left: calc(12rem/16);
        font-size: calc(16rem/16);
        opacity: 0.7;
        z-index: 0;
        transition: top .25s $sexyEase, font-size .25s $sexyEase, opacity .25s $sexyEase;
    }

    span {
        font-size: calc(14rem/16);
        font-weight: 500;
        width: calc(100% - (60rem/16));
        &.error-message {
            width: 100%;
            text-align: center;
            color: $siteError;
        }
    }

    input[type=checkbox] {
        margin: 0 calc(10rem/16) 0 0;
    }

    &:hover {
        input {
            box-shadow: 0 0 calc(2rem/16) calc(1rem/16) rgba(0,0,0,0.3);
        }
    }

    &.has-error {
        input {
            box-shadow: inset 0 0 0 calc(2rem/16) $siteError;
        }
    }

    &.is-focused {
        input {
            box-shadow: inset 0 0 0 calc(2rem/16) $sitePrimaryLightest;
        }
    }

    &.is-focused,
    &.has-value {
        label {
            opacity: 1;
            top: calc(5rem/16);
            font-size: calc(12rem/16);
        }
    }
}

input[type="checkbox"] {
    accent-color: $sitePrimaryLightest;
    margin: 0;
    width: calc(20rem/16);
    height: calc(20rem/16);
    background: $darkModeLight;
    cursor: pointer;
}

input[type="checkbox"]:before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 0 calc(6rem/16) $white, 0 0 0 calc(1rem/16) $lightGrey;
    background-color: $white;
}

input[type="checkbox"]:checked:before {
    box-shadow: inset 0 0 0 calc(6rem/16) $white, 0 0 0 calc(1rem/16) $sitePrimaryLightest;
    background-color: $sitePrimaryLightest;
}

body::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0);
}

body::-webkit-scrollbar {
    width: 12px;
    background-color: #ffffff00;
}

body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

.is-dark-mode {
    input[type="text"],
    input[type="date"],
    input[type="time"],
    input[type="tel"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="url"],
    input[type="email"],
    input[type="number"],
    input[type="search"],
    input[type="file"],
    input[type="password"],
    select,
    .textbox,
    textarea {
        color: $lighterGrey;
        transition: box-shadow .15s $sexyEase;
        &:last-of-type {
            margin: 0;
        }
    }

    .form__input-wrapper {
        .input-wrapper__cover {
            background: $darkModeLight;
        }

        &:hover {
            input {
                box-shadow: inset 0 0 calc(2rem/16) calc(1rem/16) rgba(255,255,255,0.2);
            }
        }

        &.has-error {
            input {
                box-shadow: inset 0 0 0 calc(2rem/16) $siteError;
            }
        }


        &.is-focused {
            input {
                box-shadow: inset 0 0 0 calc(2rem/16) $sitePrimaryLightest;
            }
        }
    }

    button,
    input[type=checkbox] {
        &:active,
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    label {
        color: $lighterGrey;
    }

    input[type="checkbox"]:before {
        box-shadow: inset 0 0 0 calc(6rem/16) $black, 0 0 0 calc(1rem/16) $darkModeLight;
        background-color: $black;
    }

    input[type="checkbox"]:checked:before {
        box-shadow: inset 0 0 0 calc(6rem/16) $black, 0 0 0 calc(1rem/16) $sitePrimaryLightest;
        background-color: $sitePrimaryLightest;
    }
}

select {
    max-width: none;
    color: $sitePrimary;
    text-transform: uppercase;
    font-size: calc(14rem/16);
    height: calc(38rem/16);
    font-weight: bold;
    padding: calc(5rem/16);
    cursor: pointer;
    //box-shadow: inset 0 0 0 (2rem/16) $sitePrimaryLight;
    &:hover,
    &:active,
    &:focus {
        outline: none;
        box-shadow: inset 0 0 0 calc(2rem/16) $sitePrimary;
    }

    option {
        text-align: center;
    }
}

textarea {
    display: block;
    resize: none;
    :-ms-input-placeholder {
        color: darken($midGrey, 5%);
        font-size: calc(14rem/16);
        font-style: normal;
        font-family: $baseFont;
    }
}

// These have to be separate
::-webkit-input-placeholder {
    color: darken($midGrey, 5%);
    font-size: calc(14rem/16);
    font-style: normal;
    font-family: $baseFont;
}

:-moz-placeholder {
    color: darken($midGrey, 5%);
    font-size: calc(14rem/16);
    font-style: normal;
    font-family: $baseFont;
}

::-moz-placeholder {
    color: darken($midGrey, 5%);
    font-size: calc(14rem/16);
    font-style: normal;
    font-family: $baseFont;
}

textarea{
    outline: none;
}
