.form__credit-card {
    padding: calc(16rem/16) calc(14rem/16);
    margin: 0 0 2rem 0;
    background: $white;
    border-radius: calc(3rem/16);
    box-shadow: 0 0 calc(2rem/16) calc(1rem/16) rgba(0,0,0,0.12);
    transition: box-shadow .25s $sexyEase;
    width: 100%;
    &.is-dark-mode {
        background: $darkModeLight;
        .StripeElement {
            input {
                color: $lighterGrey;
            }
        }

        &:hover {
            box-shadow: inset 0 0 calc(2rem/16) calc(1rem/16) rgba(255,255,255,0.2);
        }
    }

    &.has-error {
        box-shadow: inset 0 0 0 calc(2rem/16) $siteError;
    }

    .StripeElement {
        &#cardNumber {
            width: 100%;
            margin: 0 0 calc(10rem/16) 0;
        }

        &#expiry {
            width: 33.333334%;
        }

        &#cvc {
            width: 33.333334%;
        }
    }

    .form__zip-code {
        width: 33.333334%;
        padding: 0;
        box-shadow: none !important;
        line-height: 1;
        height: calc(16.8rem/16);
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        .StripeElement {
            &#cardNumber {
                width: calc(100% - (160rem / 16));
                margin: 0;
            }

            &#expiry {
                width: calc(70rem / 16);
            }

            &#cvc {
                width: calc(40rem / 16);
            }
        }

        .form__zip-code {
            width: calc(50rem / 16);
            transform: translateY(calc(-1rem/16));
        }
    }
}



