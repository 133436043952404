.layout--slider-featured-content {
    position: relative;
    overflow: hidden;
    background: $white;
    margin: 0 auto;
    box-shadow: 0 0 calc(2rem/16) 0 rgba(0,0,0,0.2);
    color: $white !important;
    .slider-featured-content__shadow {
        height: 5rem;
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, $darkestGrey 100%);
        top: unset;
        bottom: 0;
    }

    .slider-featured-content__radial-timer {
        position: absolute;
        top: calc(16rem/16);
        right: calc(16rem/16);
        opacity: 0.6;
    }

    .slider-featured-content__inner {
        transition: transform 0.75s $sexyEase;
        h1, h2, p {
            text-align: center;
            position: relative;
            overflow: hidden;
            span {
                display: block;
                transform: translateY(150%);
                transition: transform 1s $sexyEase .25s;
            }
        }

        .slider-featured-content__button-wrapper {
            margin: 1rem 0 0 0;
        }

        button,
        a {
            transform: translateY(150%);
            transition: transform 1s $sexyEase .25s, background .25s $sexyEase;
            background: $lighterGrey;
            color: $black;
            width: auto;
            padding: calc(5rem/16) calc(45rem/16);
            &:hover,
            &:focus,
            &:active {
                background: $white;
            }
        }

        h1, h2, p {
            text-align: left;
        }

        h1, h2 {
            max-width: calc(650rem/16);
        }

        h1 {
            font-family: $tertiaryFont;
            font-weight: 600;
            margin: 0 0 1rem 0;
        }

        h2 {
            font-size: calc(22rem/16);
            line-height: 1.4;
            margin: 0 0 .5rem 0;
        }

        p {
            margin: 0;
            font-family: $baseFont;
            font-size: calc(14rem/16);
            max-width: calc(500rem/16);
        }

        .slider-featured-content__slide {
            padding: 4rem 2rem 5rem 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            &.is-active {
                h1, h2, p, .slider-featured-content__button-wrapper {
                    span,
                    button,
                    a {
                        transform: translateY(0);
                    }
                }
            }
        }

        .slider-featured-content__content {
            position: relative;
            z-index: 2;
            width: 100%;
            max-width: calc(1730rem/16);
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .ickonic-button {
                text-transform: uppercase;
            }
        }
    }

    .slider-featured-content__controls {
        display: none;
        max-width: calc(1900rem/16);
        @media screen and (min-width: calc(1900em/16)) {
            left: calc(50% - calc(950rem/16));
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        .slider-featured-content__inner {
            .slider-featured-content__slide {
                padding: 4rem 4rem 8rem 4rem;
                justify-content: flex-start;
                button,
                a {
                    margin: 0;
                }
            }
        }

        .slider-featured-content__controls {
            position: absolute;
            display: flex;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            justify-content: space-between;
        }

        .slider-featured-content__dots {
            position: absolute;
            bottom: 3rem;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(40rem/16);
            z-index: 1;
            .slider-featured-content__dot-control {
                width: calc(12rem/16);
                height: calc(12rem/16);
                border-radius: calc(6rem/16);
                margin: 0 calc(8rem/16);
                background: none;
                cursor: pointer;
                transition: background .25s $sexyEase;
                box-shadow: inset 0 0 0 calc(2rem/16) $white;
                &.is-active {
                    background: $white;
                }
            }
        }
    }

    @media screen and (min-width: $breakpointTablet) {
        .slider-featured-content__shadow {
            height: 7rem;
        }

        .slider-featured-content__inner {
            h1 {
                font-size: calc(50rem/16);
            }

            h2 {
                font-size: calc(25rem/16);
            }

            .slider-featured-content__slide {
                padding: 6rem 4rem 8rem 4rem;
            }
        }
    }

    @media screen and (min-width: $breakpointWide) {
        .slider-featured-content__inner {
            .slider-featured-content__slide {
                padding: 8rem 4rem 10rem 4rem;
            }
        }
    }

    @media screen and (min-width: $breakpointSuperWide) {
        .slider-featured-content__inner {
            .slider-featured-content__slide {
                padding: 10rem 5rem 12rem 5rem;
            }
        }
    }

    &.is-dark-mode {
        background: $darkModeLight;
    }
}
